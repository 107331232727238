#hero {
  background-image: url("/images/home-hero.jpg");
  height: 100vh;
}

#about {
  background-position: top;
  background-image: url("/images/about-hero.jpg");
  height: 80vh;
}

#songlist {
  background-position: top;
  background-image: url("/images/songlist-hero.jpg");
  height: 100vh;
}

#performances {
  background-position: top;
  background-image: url("/images/performances-hero.jpg");
  height: 90vh;
}

#contact {
  background-position: top;
  background-image: url("/images/contact-hero.jpg");
  height: 90vh;
}

.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

header {
  z-index: 900;
}

.bg-dark {
  --bs-bg-opacity: 0.8;
}

a.photo {
  cursor: pointer;
}

h3 a {
  text-decoration: none;
}
